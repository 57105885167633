import React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'
import linkResolver from '../utils/linkResolver.js'

const PreviewPage = ({ isPreview, isLoading, previewData }) => {
  return (
    <div className={`${isPreview === false ? ( 'stars bg-gray-900 text-gray-100' ):( 'bg-gray-100 text-blue-500' )} absolute inset-0 z-40`}>
      <div className='text-3xl text-center font-black  md:text-6xl text-5xl z-10 relative flex flex-col justify-center h-screen'>
        {isPreview === false ? ( 'There\'s no preview here' ):( 'loading preview' )}
      </div>
    </div>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'unicard',
  linkResolver,
})
