const linkResolver = ({ node, key, value }) => doc => {
  var lang = ''
  if( doc.lang && doc.lang !== 'en-gb') {
    lang = '/' + doc.lang.slice(0, 2)
  }
  if (doc.uid !== 'index' && doc.type === 'page') {
    return `${lang}/${doc.uid}`
  }
  if (doc.type === 'blog_post') {
    return `${lang}/news/${doc.uid}`
  }
  if (doc.type === 'job_post') {
    return `${lang}/career/${doc.uid}`
  }
  return `${lang}/`
}

module.exports = linkResolver
